import React from "react";
import LogoCardDetailed from "./LogoCardDetailed";
import LatestSermon from "./LatestSermon";
import LatestMarriageMatters from "./LatestMarriageMatters";
import DownloadYouVersion from "./DownloadYouVersion";
import ImageActionCard from "./ImageActionCard";
import HeadingActionCard from "./HeadingActionCard";

function Home(props) {
  const { formatDate, selectAudio } = props;

  return (
    <>
      <LogoCardDetailed />
      <LatestSermon formatDate={formatDate} selectAudio={selectAudio} />
      <ImageActionCard
        cardTitle="Marriage Matters Sign Up"
        imageUrl="https://www.centerpointesc.com/images/marriage-matters.jpg"
        actionText="sign up"
        actionUrl="https://centerpointesc.breezechms.com/form/MarriageMattersFeb2025"
        subHeading="6:15–9 p.m., Friday, February 28 @ Center Pointe"
        bodyContent="Join us for a one-evening marriage seminar, 6:15–9 p.m. on Friday, February 28. $60 per couple. Please register by February 23."
      />
      <ImageActionCard
        cardTitle="Women's Event with Dawn Smith Jordan Sign Up"
        imageUrl="https://www.centerpointesc.com/images/dawn-smith-jordan.jpg"
        actionText="sign up"
        actionUrl="https://centerpointesc.breezechms.com/form/DawnSmithJordan2025"
        subHeading="9:30 a.m. – noon, Saturday, March 15 @ Center Pointe"
        bodyContent="Join us for a one-time free event for women, 9:30 a.m. – noon, Saturday, March 15."
      />
      <LatestMarriageMatters
        formatDate={formatDate}
        selectAudio={selectAudio}
      />
      <DownloadYouVersion />
      <HeadingActionCard
        title="UPDATE CONTACT INFO"
        paletteColor="englishViolet"
        actionText="go to form"
        actionUrl="https://centerpointesc.breezechms.com/form/UpdateInformation"
        bodyContent="If you would like to send us your latest contact information, please fill out this online form. If you provide an email address, you will receive a confirmation upon submission of the form, as well as an email with instructions on creating a Center Pointe account with Breeze if you do not already have one."
      />
      <ImageActionCard
        title="Lexington Christian Counseling"
        imageUrl="https://centerpointesc.com/images/LexCounsel-ad.jpg"
        actionText="visit website"
        actionUrl="http://www.lexingtonchristiancounseling.org"
        bodyContent="We believe in the value of having someone to talk to when we need a little help to get through life's tough moments or to improve our relationships with those we care most about. Lexington Christian Counseling is a vital resource to our congregation and our go-to for counseling referrals."
      />
    </>
  );
}
export default Home;
